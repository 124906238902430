#earth {
    box-shadow: inset 16px 0 40px 6px rgb(0, 0, 0),
        inset -3px 0 6px 2px rgba(255, 255, 255, 0.2);
    animation-name: rotate;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from { background-position-x: 0px; }
    to { background-position-x: 466px; }
}

/* Bigger than 1024px width ("lg" prefix on Tailwind CSS) */
@media (min-width: 1024px) {
    @keyframes rotate {
        from { background-position-x: 0px; }
        to { background-position-x: 840px; }
    }
}
  
/* Bigger than 768px width ("md" prefix on Tailwind CSS) */
@media (min-width: 768px) {
    @keyframes rotate {
        from { background-position-x: 0px; }
        to { background-position-x: 700px; }
    }
}

/* Bigger than 640px width ("sm" prefix on Tailwind CSS) */
@media (min-width: 640px) {
    @keyframes rotate {
        from { background-position-x: 0px; }
        to { background-position-x: 560px; }
    }
}

@keyframes border-pulse {
    50% {
        border-color: white;
    }
}
.animate-border-pulse {
    animation: border-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes bg-pulse {
    50% {
        background-color: white;
    }
}
.animate-bg-pulse {
    animation: bg-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes text-pulse {
    50% {
        color: white;
    }
}
.animate-text-pulse {
    animation: text-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}